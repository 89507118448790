<template>
  <v-dialog persistent v-model='dialog' width='720px'>
    <template v-slot:activator="{ on, attrs }">
      <v-btn text @click="openDialog()">
        <v-icon left small color="gray">mdi-file-document-multiple-outline</v-icon>
        {{ $t('reports.titles.generateReports') }}
      </v-btn>
    </template>
    <v-card elevation='0'>
      <v-card-title class='secondary white--text py-4'>
        {{ $t('reports.titles.generateReports') }}
      </v-card-title>
      <v-card-text class='mt-12 mb-4' v-if="dialog">
        <v-row justify="start">
          <v-col cols="5" class="mr-0 pr-0">
            <a-select-lots
              class="mr-1"
              :refer="`lots`"
              @data:change="getProducts($event)"
              :multiple="false"
              :field-name="$t('reports.fields.lot')"
              :show-selected="false"
              :clearable="true"
            />
          </v-col>
          <v-col cols="5" class="mx-0 px-0">
            <v-autocomplete
              :no-data-text="$t('general.fields.noDataAvailable')"
              background-color="#EAEAEA80"
              persistent-hint
              small
              flat
              outlined
              dense
              ref="productFilters"
              clearable
              item-color="secondary"
              :items="products"
              v-model="product"
              small-chips
              hide-details
              multiple
              color="secondary"
              :label="$t('records.label.titles.product')"
              :loading="loadingProducts"
              :disabled="!(products && products.length > 0) || !lot"
              @click:clear="clearProductData"
            >
              <template v-slot:label>
                {{$t('records.label.titles.product')}} <span class="red--text"><strong>*</strong></span>
              </template>
              <template v-slot:selection="{ item, index }">
                <v-chip small v-if="index === 0">
                  <span>{{ item | truncate(15) }}</span>
                </v-chip>
                <span v-if="index === 1" class="grey&#45;&#45;text caption"
                >(+{{ product.length - 1 }}
                  {{ $t('general.titles.others') }})</span
                >
              </template>
              <template v-slot:item="{ item, attrs }">
                <div style="max-width: 360px">
                  <v-checkbox
                    :input-value="attrs.inputValue"
                    color="secondary"
                    class="pt-2 pb-2 mt-0"
                    :hide-details="true"
                  >
                    <template v-slot:label>
                      <read-more
                        :classes="'mb-0'"
                        :text="item"
                        :max-chars="80"
                        :font-size="14"
                      />
                    </template>
                  </v-checkbox>
                </div>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols='2'>
            <v-btn
              :disabled="!lot || !(product.length > 0)"
              class="ml-1"
              tile
              :elevation="0"
              height="38"
              color="#d8d8d896"
              style="border-radius: 5px !important;"
              @click="add()"
            >
              <v-icon color="#6D6D6D">
                mdi-plus
              </v-icon>
            </v-btn>
          </v-col>
          <v-col cols='12' class="mx-0 px-0">
            <v-data-table :items='toSave'
                          :headers='headers'
                          :footer-props="{
                            ...rowsPerPageItemsOptions,
                            itemsPerPageText: $t('general.table.itemsPerPageText'),
                            pageText: '{0}-{1} ' + $t('general.table.pageText') + ' {2}',
                          }"
                          :no-data-text="$t('general.ui.not_data_found')"
            >
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="neuter darken-1"
          text
          @click="closeDialog()"
        >
          {{ $t("general.buttons.close") }}
        </v-btn>
        <v-btn
          color="green darken-1"
          text
          @click="generateReport()"
          :disabled='!(toSave.length > 0)'
        >
          {{ $t("general.buttons.generate") }}
        </v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import sortList from '@/services/OrderListService'
  import i18n from '@/plugins/i18n'
  import ReadMore from '@/components/ReadMoreComponent'
  import _ from 'lodash'
  import ASelectLots from "@/components/common/AAutocomplete/ASelectLots.vue";

  export default {
    name: 'GenerateNewTraceabilityReportTable',
    components: { ReadMore, ASelectLots },

    props: {
      reportType: {
        type: String,
        required: true
      }
    },

    data:() => ({
      profile: null,
      dialog: false,
      product: [],
      products: [],
      lot: null,
      lotsIds: [],
      toSave: [],
      loadingProducts: false,
      checkboxState: {
        products: false,
        lots: false,
      },
      rowsPerPageItemsOptions: {
        itemsPerPageOptions: [5, 10],
      },
      selectAllCheck: false
    }),

    computed: {
      ...mapGetters({
        lots: 'details/lots'
      }),
      headers: () => [
        { text: i18n.t('reports.fields.lot'), value: "lot" },
        { text: i18n.t('records.label.titles.product'), value: "product" },
      ]
    },

    mounted() {
      this.profile = JSON.parse(localStorage.getItem('profile'))
    },

    methods: {
      ...mapActions({
        fetchListStructureProducts: 'details/fetchListStructureProducts',
        generateReportsByLotAndProduct: 'reports/generateReportsByLotAndProduct',
      }),

      add() {
        _.forEach(this.product, (p) => {
          const data = _.filter(this.toSave, { lot: this.lot.name, product: p })
          if (!(data.length > 0)) {
            this.toSave.push({
              product: p,
              lot: this.lot.name
            })
          }
        })
        this.product = []
        this.lot = null
        this.$root.$emit("clear:selected:lots")
      },
      openDialog() {
        this.$emit('closeMenu', false)
        this.dialog = true
      },
      closeDialog() {
        this.$emit('closeMenu', false)
        this.dialog = false
        this.clearLotData()
      },
      generateReport() {
        let reportType = "TRACEABILITY"

        switch (this.reportType) {
          case "rastreability":
            reportType =  "TRACEABILITY"
            break;
          case "recall":
            reportType =  "RECALL"
            break;
        }

        this.generateReportsByLotAndProduct({
          lotAndProductList: this.toSave,
          reportType: reportType
        });
        this.dialog = false
        this.clearLotData()
      },
      clearProductData() {
        this.checkboxState.products = false
        this.product = []
      },
      async getProducts(lot) {
        this.loadingProducts = true
        this.lot = lot
        await this.fetchListStructureProducts([this.profile, lot.name, this.$toast, false])
          .then((data) => {
            this.products = data;
          })
          . finally(() => {
            this.loadingProducts = false
          })
      },
      clearLotData() {
        this.checkboxState.lots = false
        this.checkboxState.products = false
        this.lot = null
        this.$root.$emit("clear:selected:lots")
        this.product = []
        this.toSave = []
      },
      orderData(list, order) {
        return sortList.orderListByUppercase(list, order)
      }
    }
  }
</script>

<style scoped>
tbody {
  height: 200px !important;
}
</style>