import LanguageService from "../services/LanguajeService";
import moment from "moment";
import LanguajeService from "@/services/LanguajeService";

export const generalMixin = {
    computed: {
        isMobile() {
            return this.$vuetify.breakpoint.mobile
        },
        height() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return 220
                case 'sm':
                    return 400
                case 'md':
                    return 500
                case 'lg':
                    return 600
                case 'xl':
                    return 840
            }
        },
        width() {
            switch (this.$vuetify.breakpoint.name) {
                case 'sm':
                    return 440
                case 'md':
                    return 960
                case 'lg':
                    return 1140
                case 'xl':
                    return 1280
            }
        },
        getValue() {
            return (structure, value) => {
                if (Array.isArray(value) && value.length === 0) {
                    console.log(value)
                    return '-';
                }

                if (value) {
                    let dateFormatted = (
                        LanguajeService.getKey3(value) + '.000Z'
                    ).slice(0, 19);

                    switch (structure.structure_type) {
                        case 'DATA':
                            return moment(dateFormatted).format('DD / MM / YYYY');
                        case 'TIME':
                            return moment(dateFormatted).format('HH:mm');
                        case 'DATATIME':
                            return moment(dateFormatted).format('DD / MM / YYYY HH:mm');
                        default:
                            return LanguajeService.getKey3(value);
                    }
                } else {
                    return '-';
                }
            }
        },
    },
    methods: {
      addCommas(nStr) {
        nStr += '';
        let x = nStr.split('.');
        let x1 = x[0];
        let x2 = x.length > 1 ? '.' + x[1] : '';
        let rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
          x1 = x1.replace(rgx, '$1' + ',' + '$2');
        }
        return x1 + x2;
      },
      translate(key, uppercase) {
        return LanguageService.getKey3(key)
      }
    }
}
